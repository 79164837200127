import get from "lodash/get";
import Icon from "@/atoms/Icon";
import kebabCase from "lodash/kebabCase";
import cx from "classnames";
import partition from "lodash/partition";
import styled from "styled-components";
import { postCompleteGuidedQuiz } from "lib/api/onboarding";

// helpers
export const getImageUrl = (asset) => get(asset, "fields.file.url", null);

export const getImageAlt = (asset) => get(asset, "field.description", null);

export const getContentType = (field) =>
  get(field, "sys.contentType.sys.id", null);

export const getFieldId = (field) => get(field, "sys.id", null);

export const getFieldRevision = (field) => get(field, "sys.revision", null);

// from hoisted field
export const getImageDimensions = (field) => get(field, "file.details.image");

// swap placeholders in richText or strings, must be in [[placeholder]] format
export const swapEntryPlaceholders = (dict, content) => {
  const entries = Object.entries(dict);
  const isString = typeof content === "string";
  let contentAsString = isString ? content : JSON.stringify(content);
  entries.map(([placeholder, value]) => {
    contentAsString = contentAsString
      .split(`[[${placeholder}]]`)
      .join(String(value));
  });
  return isString ? contentAsString : JSON.parse(contentAsString);
};

/** somewhat deprecated - use slug if available on entry **/
export const getReferenceByComponent = (references, componentName) =>
  references.find((s) => s.component === componentName);

export const getReferenceBySlug = (references, slug) =>
  references.find((s) => s.slug === slug);

export const getFormFieldAttrs = (entry, name) => {
  const { label, placeholder, helpText } =
    entry.references?.find((r) => r.name === name) || {};
  return { label, placeholder, helpText, name };
};

export const renderIconFromText = ({ text, size, className }) => {
  const iconRegex = new RegExp(/(\[\[icon-\w+-\w+]])/g);
  const matches = text.match(iconRegex);
  if (matches) {
    const renderIcon = () => {
      const placeholder = matches[0];
      const innerStr = placeholder.replace(/[[\]]/g, "");
      const [name, color] = innerStr.split("-").slice(1);

      return (
        <Icon
          name={kebabCase(name)}
          size={size}
          fill={`#${color}`}
          className={className}
          key={innerStr}
        />
      );
    };
    return [renderIcon(), text.replace(iconRegex, "")];
  }
  return text;
};

const StyledListItem = styled.li`
  &:not(.hasIcon) {
    padding-left: 1.5rem;
    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0.5rem;
      left: 0;
      border-radius: 50%;
      height: 0.5rem;
      width: 0.5rem;
      background-color: gray;
    }
  }
  &.hasIcon {
    > p {
      display: flex;
      align-items: center;
    }
  }
`;

export const renderListItemIcon = (node, children) => {
  const hasIcon = JSON.stringify(node).includes("[[icon-");
  return (
    <StyledListItem
      className={cx("my-4", { hasIcon })}
      key={JSON.stringify(node)}
    >
      {children}
    </StyledListItem>
  );
};

export const extractPrimary = (sections, componentName) => {
  const [primaryEntry, extraEntries] = partition(
    sections,
    (s) => s.component === componentName
  );
  return [primaryEntry[0], extraEntries];
};

export const getLastUpdated = (entry) => get(entry, "sys.updatedAt", null);

/**
 * @param {import("@literati/public-ui-shared-react").KidsTypes.IImageFields} contentfulImageInfo
 * @returns {import("@literati/public-ui-shared-react").ImageProps}
 */
export function formatContentfulImageForNextImage(contentfulImageInfo) {
  return contentfulImageInfo
    ? {
        ...(contentfulImageInfo?.file?.details?.image || {}),
        src: contentfulImageInfo?.file?.url || null,
        alt: contentfulImageInfo?.description || null,
      }
    : null;
}

/**
 * Utility for getting GSU completed quiz data.
 * @note this is a pattern we were repeating on 3 different pages.
 *
 * @param {Object} args
 * @param {any} args.cmsGuidedSignUpReferences - response from getEntriesBySlug("guidedSignUp", "guided-sign-up-funnel", { raw: true })
 * @param {import("next").GetServerSidePropsContext} [args.ctx]
 * @param {any} [args.subscriptionId]
 * @returns Promise<any>
 */
export async function getCompletedGuidedSignUpQuiz({
  cmsGuidedSignUpReferences,
  ctx,
  subscriptionId,
}) {
  const cmsGuidedSignUp = cmsGuidedSignUpReferences?.items?.[0];
  const quizSlug = cmsGuidedSignUp?.fields?.slug;
  const quizVersion = cmsGuidedSignUp?.fields?.version?.toString();
  return await postCompleteGuidedQuiz({
    quizSlug,
    quizVersion,
    ctx,
    subscriptionId,
  });
}
