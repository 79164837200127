import PropTypes from "prop-types";
import styled from "styled-components";
import Icon from "@/atoms/Icon";
import Button from "@/atoms/Button";

const BackButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 44px;
  color: ${({ $disabled, theme }) =>
    $disabled ? theme.colors["gray2"] : theme.colors["ink-black"]};
`;

const CarrotIcon = styled(Icon)`
  transform: rotate(180deg);
`;

const BackText = styled.span`
  margin: 0 0 0 1rem;
  font-weight: 800;
`;

/**
 * Back button that includes carrot and text.
 *
 * @component
 * @param {Object} props
 * @param {Function} props.onClick
 * @param {Boolean} props.suppressText
 */
function DLSBackButton(props) {
  const { className, onClick, suppressText, ...buttonProps } = props;
  return (
    <BackButton
      {...buttonProps}
      className={className}
      $disabled={buttonProps?.disabled}
      level="unstyled"
      onClick={onClick}
    >
      <CarrotIcon name="chevron-right" size="1rem" />
      {!suppressText && <BackText>Back</BackText>}
    </BackButton>
  );
}

DLSBackButton.defaultProps = {
  onClick: () => {},
  suppressText: false,
};

DLSBackButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  suppressText: PropTypes.bool,
};

export default DLSBackButton;
