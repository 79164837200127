import PropTypes from "prop-types";
import styled, { createGlobalStyle } from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import Button from "@/atoms/Button";
import { BreakpointKey } from "theme/breakpoints";
import DLSBackButton from "components/DLS/DLSBackButton";
import { DesktopImageContainer } from "components/DLS/DLSContainers";

export const footerOffsetValue = 100;

const GlobalStyle = createGlobalStyle`
  footer {
    padding-bottom: 124px !important; // offset for StickyCTA
  }
`;

const Container = styled.section`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100px;
  z-index: 50;
  background: ${({ theme }) => theme.colors["white"]};
  box-shadow: ${({ theme }) => `0px 0px 15px ${theme.colors["newsprint1"]}`};
`;

const Content = styled.div`
  display: flex;
  justify-content: center;

  ${({ theme }) => theme.media["md"]} {
    justify-content: ${({ $suppressBackButton }) =>
      $suppressBackButton ? "flex-end" : "space-between"};
    align-items: center;
  }
`;

const CTA = styled(Button)`
  margin: 1.5rem 0;
  width: 100%;

  ${({ theme }) => theme.media["md"]} {
    width: 273px;
  }
`;

/**
 * CTA meant to be anchored to the bottom of the page in fixed position.
 *
 * @note content is meant to scroll behind this element
 * @note this component adds spacing to the global footer
 * @note undocumented props are spread on the button component
 *
 * @param {Object} props
 * @param {Object} props.backButtonProps - spread through to the back button
 * @param {String} [props.className]
 * @param {Object} props.ctaButtonProps - spread through to the primary CTA
 * @param {Boolean} [props.suppressBackButton]
 * @param {Boolean} [props.useMotion]
 * @param {Boolean} [props.visible]
 */
function DLSStickyFooter({
  backButtonProps,
  className,
  ctaButtonProps,
  suppressBackButton,
  useMotion,
  visible,
}) {
  const ctaLabel = ctaButtonProps?.label || "Continue";

  return useMotion ? (
    <AnimatePresence>
      {visible && (
        <motion.div
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          id={"sticky-cta-motion"}
          initial={{ opacity: 0 }}
          key="sticky-cta-motion"
        >
          <Container className={className}>
            <GlobalStyle />
            <Content
              className="content-spacing"
              $suppressBackButton={suppressBackButton}
            >
              {!suppressBackButton && (
                <DesktopImageContainer $breakpoint={BreakpointKey.MD}>
                  <DLSBackButton {...backButtonProps} />
                </DesktopImageContainer>
              )}
              <CTA color="teal" {...ctaButtonProps}>
                {ctaLabel}
              </CTA>
            </Content>
          </Container>
        </motion.div>
      )}
    </AnimatePresence>
  ) : (
    <Container className={className}>
      <GlobalStyle />
      <Content
        className="content-spacing"
        $suppressBackButton={suppressBackButton}
      >
        {!suppressBackButton && (
          <DesktopImageContainer $breakpoint={BreakpointKey.MD}>
            <DLSBackButton {...backButtonProps} />
          </DesktopImageContainer>
        )}
        <CTA color="teal" {...ctaButtonProps}>
          {ctaLabel}
        </CTA>
      </Content>
    </Container>
  );
}

DLSStickyFooter.defaultProps = {
  backButtonProps: {},
  ctaButtonProps: {},
  suppressBackButton: false,
  useMotion: false,
  visible: true,
};

DLSStickyFooter.propTypes = {
  backButtonProps: PropTypes.object,
  className: PropTypes.string,
  ctaButtonProps: PropTypes.object,
  suppressBackButton: PropTypes.bool,
  useMotion: PropTypes.bool,
  visible: PropTypes.bool,
};

export default DLSStickyFooter;
