import {
  ParsedErrorResponse,
  isAPIError,
  requestJSON,
  requestJSONBasic,
} from "lib/api/base";
import {
  GetGuidedSignupAnswersResponse,
  PageNumberPaginationResponseSchema_BookSchema_,
  BookSchema,
  SnakeToCamelCaseNested,
  GetGuidedSignupConfirmationResponse,
} from "@literati/kids-api";
import { mapKeysSnakeCase } from "utils";
import { GetServerSidePropsContext } from "next";

export const BASE_URL = "/api/onboarding";

interface GetGuidedSignUpAnswersProps {
  quizSlug: string;
  quizVersion: string | number;
  subscriptionId?: number | null;
  token?: string | null;
  ctx?: GetServerSidePropsContext;
}
export async function getGuidedSignUpAnswers({
  quizSlug,
  quizVersion,
  subscriptionId,
  token,
  ctx,
}: GetGuidedSignUpAnswersProps) {
  const url = `${BASE_URL}/get-guided-signup-answers` as const;
  const res = await requestJSON(
    url,
    mapKeysSnakeCase({
      quizSlug,
      version: quizVersion,
      token,
      subscriptionId,
    }),
    "GET",
    { mapKeys: false, ctx }
  );

  if (isAPIError(res)) {
    return res;
  }

  // Coerce type to non-camel case version of response
  // We intentionally avoid case conversion here since the
  // keys in the answers are case sensitive
  const data = res as unknown as GetGuidedSignupAnswersResponse;

  return data?.["onboarding_quiz"]?.answers || {};
}

/**
 * Saves one to many answers for a question in a guided sign up quiz.
 *
 * @param {Object} args
 * @param {String} args.quizSlug
 * @param {String} args.quizVersion
 * @param {String} args.subscriptionId
 * @param {Array.<{answer: Array, question: Object}>} args.submissions
 */
export async function postGuidedSignUpAnswers({
  quizSlug,
  quizVersion,
  subscriptionId,
  submissions,
}: any) {
  const payload = mapKeysSnakeCase({
    quizSlug,
    quizVersion: quizVersion?.toString(),
    subscriptionId,
    submissions,
  });

  return await requestJSONBasic(
    `${BASE_URL}/submit-guided-signup-answer`,
    payload,
    "POST"
  );
}

export type BookSchemaCamelCase = SnakeToCamelCaseNested<BookSchema>;
export type GetBookOptionsResponseCamelCase =
  SnakeToCamelCaseNested<PageNumberPaginationResponseSchema_BookSchema_>;

export async function getBookOptions(
  {
    childAge,
    limit = 35,
    subscriptionId,
  }: {
    childAge?: string | number | null;
    limit?: number;
    subscriptionId?: number;
  },
  ctx?: GetServerSidePropsContext
): Promise<ParsedErrorResponse | GetBookOptionsResponseCamelCase> {
  return await requestJSON(
    `${BASE_URL}/book-options/`,
    mapKeysSnakeCase({ childAge, subscriptionId, limit }),
    "GET",
    {
      ctx,
    }
  );
}

export enum PreferenceValue {
  Dislike = -1,
  Neutral = 0,
  Like = 1,
  SuperLike = 2,
}
export interface BookPreference {
  bookId: number;
  preferenceValue: PreferenceValue;
  ownsBook?: boolean;
}

export async function submitBookPreferences(
  preferences: BookPreference[],
  subscriptionId?: number | null,
  ctx?: GetServerSidePropsContext
) {
  const payload = {
    book_preferences: preferences.map(mapKeysSnakeCase),
    subscription_id: subscriptionId,
  };
  return await requestJSONBasic(
    `${BASE_URL}/submit-book-preferences`,
    payload,
    "POST",
    { ctx }
  );
}

/**
 * Informs backend that the Guided Sign Up quiz is complete and receives a
 * response containing the user's reading level.
 *
 * `subscriptionId` is not necessary if called pre-purchase, since a subscription
 * has no id until the after the user purchases one.
 */
export async function postCompleteGuidedQuiz({
  quizSlug,
  quizVersion,
  subscriptionId,
  ctx,
}: {
  quizSlug: string;
  quizVersion: string;
  subscriptionId?: string;
  ctx?: any;
}): Promise<SnakeToCamelCaseNested<GetGuidedSignupConfirmationResponse>> {
  const payload = mapKeysSnakeCase({
    quizSlug,
    quizVersion: quizVersion?.toString(),
    subscriptionId,
  });

  return await requestJSONBasic(
    `${BASE_URL}/complete-guided-quiz`,
    payload,
    "POST",
    { ctx }
  );
}

export type PostCompleteGuidedQuizReturnType = ReturnType<
  typeof postCompleteGuidedQuiz
>;
